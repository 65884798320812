//スクロール料に合わせて線を色付きで描画する処理
window.addEventListener('scroll', function () {
    // セクション要素を取得
    var aboutSection = document.querySelector('.about');
    var specialSection = document.querySelector('.special');
    var specialbeforeSection = document.querySelector('.specialbefore');
    var specialafterSection = document.querySelector('.specailafter');
    var workSection = document.querySelector('.work');
    var benefitSection = document.querySelector('.benefit');
    var applicationsSection = document.querySelector('.applications');
    var entrySection = document.querySelector('.entry');

    // セクションの位置情報を取得
    var aboutBounding = aboutSection.getBoundingClientRect();
    var specialBounding = specialSection.getBoundingClientRect();

    var specialbeforeBounding = specialbeforeSection.getBoundingClientRect();
    var specialafterBounding = specialafterSection.getBoundingClientRect();

    var workBounding = workSection.getBoundingClientRect();
    var benefitBounding = benefitSection.getBoundingClientRect();
    var applicationsBounding = applicationsSection.getBoundingClientRect();

    // 画面の高さ
    var windowHeight = window.innerHeight;

    if (window.innerWidth <= 768) {
        // スマートフォン向けの処理

        var line1PathSP = document.getElementById('line1SP');
        var line1SP = line1PathSP.getTotalLength();

        var line2PathSP = document.getElementById('line2SP');
        var line2SP = line2PathSP.getTotalLength();

        var line3PathSP = document.getElementById('line3SP');
        var line3SP = line3PathSP.getTotalLength();

        var line4PathSP = document.getElementById('line4SP');
        var line4SP = line4PathSP.getTotalLength();

        var line5PathSP = document.getElementById('line5SP');
        var line5SP = line5PathSP.getTotalLength();

        //about
        let aboutvisiblePercentageSP = (((window.scrollY - 500) / aboutSection.clientHeight) * line1SP);
        if (aboutBounding.top < windowHeight && aboutBounding.bottom > 0) {
            if (aboutvisiblePercentageSP < line1SP) {
                line1SPFill.setAttributeNS(null, "stroke-dasharray", line1SP);
                line1SPFill.setAttributeNS(null, "stroke-dashoffset", (line1SP - (aboutvisiblePercentageSP)));
            } else {
                line1SPFill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        //special
        specailPercentageSP = (((window.scrollY - aboutSection.clientHeight - 500) / specialSection.clientHeight) * line2SP);
        if (specialBounding.top < windowHeight && specialBounding.bottom > 0) {
            if (specailPercentageSP < line2SP) {
                line2SPFill.setAttributeNS(null, "stroke-dasharray", line2SP);
                line2SPFill.setAttributeNS(null, "stroke-dashoffset", (line2SP - (specailPercentageSP)));
            } else {
                line2SPFill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        //work
        workPercentageSP = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight) - 500) / workSection.clientHeight) * line3SP);
        if (workBounding.top < windowHeight && workBounding.bottom > 0) {
            if (workPercentageSP < line3SP) {
                line3SPFill.setAttributeNS(null, "stroke-dasharray", line3SP);
                line3SPFill.setAttributeNS(null, "stroke-dashoffset", (line3SP - (workPercentageSP)));
            } else {
                line3SPFill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        //benefit
        benefitvisiblePercentageSP = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight + workSection.clientHeight) - 500) / benefitSection.clientHeight) * line4SP);
        if (benefitBounding.top < windowHeight && benefitBounding.bottom > 0) {
            if (benefitvisiblePercentageSP < line4SP) {
                line4SPFill.setAttributeNS(null, "stroke-dasharray", line4SP);
                line4SPFill.setAttributeNS(null, "stroke-dashoffset", (line4SP - (benefitvisiblePercentageSP)));
            } else {
                line4SPFill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        //applications
        applicationsvisiblePercentageSP = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight + workSection.clientHeight + benefitSection.clientHeight) - 500) / applicationsSection.clientHeight) * line5SP);
        if (applicationsBounding.top < windowHeight && applicationsBounding.bottom > 0) {
            if (applicationsvisiblePercentageSP < line5SP) {
                line5SPFill.setAttributeNS(null, "stroke-dasharray", line5SP);
                line5SPFill.setAttributeNS(null, "stroke-dashoffset", (line5SP - (applicationsvisiblePercentageSP)));
            } else {
                line5SPFill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        //entry
        entryvisiblePercentage = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight + workSection.clientHeight + benefitSection.clientHeight + applicationsSection.clientHeight) - 500) / entrySection.clientHeight) * 100);

        if (entryvisiblePercentage > 0) {
            document.querySelector('.test').style.height = entryvisiblePercentage + '%';
            if (entryvisiblePercentage > 100) {
                document.querySelector('.test').style.height = '100%';
            }
        } else {
            document.querySelector('.test').style.height = '0%';
        }

    } else if (window.innerWidth <= 1080) {
        //タブレット向け

        //workの画像切り替え
        // worBtn1にホバーしたときの処理
        document.getElementById('worBtn1').addEventListener('mouseenter', function () {
            // すべてのworkImgのis-activeクラスを削除
            removeActiveClassFromImages();
            // workImg1にis-activeクラスを追加
            document.getElementById('workImg1').classList.add('is-active');
        });

        // worBtn2にホバーしたときの処理
        document.getElementById('worBtn2').addEventListener('mouseenter', function () {
            // すべてのworkImgのis-activeクラスを削除
            removeActiveClassFromImages();
            // workImg2にis-activeクラスを追加
            document.getElementById('workImg2').classList.add('is-active');
        });

        var line1PathTab = document.getElementById('line1Tab');
        var line1Tab = line1PathTab.getTotalLength();

        var line21PathTab = document.getElementById('line21Tab');
        var line21Tab = line21PathTab.getTotalLength();

        var line2PathTab = document.getElementById('line2Tab');
        var line2Tab = line2PathTab.getTotalLength();

        var line3PathTab = document.getElementById('line3Tab');
        var line3Tab = line3PathTab.getTotalLength();

        var line4PathTab = document.getElementById('line4Tab');
        var line4Tab = line4PathTab.getTotalLength();

        var line5PathTab = document.getElementById('line5Tab');
        var line5Tab = line5PathTab.getTotalLength();


        let aboutvisiblePercentageTab = (((window.scrollY - 500) / aboutSection.clientHeight) * line1Tab);
        if (aboutBounding.top < windowHeight && aboutBounding.bottom > 0) {
            if (aboutvisiblePercentageTab < line1Tab) {
                line1TabFill.setAttributeNS(null, "stroke-dasharray", line1Tab);
                line1TabFill.setAttributeNS(null, "stroke-dashoffset", (line1Tab - (aboutvisiblePercentageTab)));
            } else {
                line1TabFill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }


        //specail
        //line
        if (specialbeforeBounding.top < windowHeight && specialafterBounding.bottom > 0) {
            specialbeforevisiblePercentageTab = (((window.scrollY - aboutSection.clientHeight - 500) / specialbeforeSection.clientHeight) * line21Tab);

            if (specialbeforevisiblePercentageTab < line21Tab && specialbeforevisiblePercentageTab >= 0) {
                line21TabFill.setAttributeNS(null, "stroke-dasharray", line21Tab);
                line21TabFill.setAttributeNS(null, "stroke-dashoffset", (line21Tab - specialbeforevisiblePercentageTab));
            } else if (specialbeforevisiblePercentageTab < 0) {
                line21TabFill.setAttributeNS(null, "stroke-dasharray", line21Tab);
                line21TabFill.setAttributeNS(null, "stroke-dashoffset", line21Tab);

            } else if (specialbeforevisiblePercentageTab > line21Tab) {
                line21TabFill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        //cuve
        if (specialafterBounding.top < windowHeight && specialafterBounding.bottom > 0) {
            specialaftervisiblePercentageTab = (((window.scrollY - (aboutSection.clientHeight + specialbeforeSection.clientHeight) - 500) / specialafterSection.clientHeight) * line2Tab - 140);

            if (specialaftervisiblePercentageTab < line2Tab && specialaftervisiblePercentageTab >= 0) {
                line2TabFill.setAttributeNS(null, "stroke-dasharray", line21Tab);
                line2TabFill.setAttributeNS(null, "stroke-dashoffset", (line21Tab - specialaftervisiblePercentageTab));
            } else if (specialaftervisiblePercentageTab < 0) {
                line2TabFill.setAttributeNS(null, "stroke-dasharray", line2Tab);
                line2TabFill.setAttributeNS(null, "stroke-dashoffset", line2Tab);
            } else if (specialaftervisiblePercentageTab > line2Tab) {
                line2TabFill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        }

        //work
        if (workBounding.top < windowHeight && workBounding.bottom > 0) {
            workvisiblePercentageTab = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight) - 500) / workSection.clientHeight) * line3Tab);
            if (workvisiblePercentageTab < line3Tab) {
                line3TabFill.setAttributeNS(null, "stroke-dasharray", line3Tab);
                line3TabFill.setAttributeNS(null, "stroke-dashoffset", (line3Tab - (workvisiblePercentageTab)));
            } else {
                line3TabFill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        benefitvisiblePercentageTab = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight + workSection.clientHeight) - 500) / benefitSection.clientHeight) * line4Tab);
        if (benefitBounding.top < windowHeight && benefitBounding.bottom > 0) {
            if (benefitvisiblePercentageTab < line4Tab) {
                line4TabFill.setAttributeNS(null, "stroke-dasharray", line4Tab);
                line4TabFill.setAttributeNS(null, "stroke-dashoffset", (line4Tab - (benefitvisiblePercentageTab)));
            } else {
                line4TabFill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        //applications
        applicationsvisiblePercentageTab = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight + workSection.clientHeight + benefitSection.clientHeight) - 500) / applicationsSection.clientHeight) * line5Tab);
        if (applicationsBounding.top < windowHeight && applicationsBounding.bottom > 0) {
            if (applicationsvisiblePercentageTab < line5Tab) {
                line5TabFill.setAttributeNS(null, "stroke-dasharray", line5Tab);
                line5TabFill.setAttributeNS(null, "stroke-dashoffset", (line5Tab - (applicationsvisiblePercentageTab)));
            } else {
                line5TabFill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        //entry
        entryvisiblePercentage = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight + workSection.clientHeight + benefitSection.clientHeight + applicationsSection.clientHeight) - 500) / entrySection.clientHeight) * 100);

        if (entryvisiblePercentage > 0) {
            document.querySelector('.test').style.height = entryvisiblePercentage + '%';
            if (entryvisiblePercentage > 100) {
                document.querySelector('.test').style.height = '100%';
            }
        } else {
            document.querySelector('.test').style.height = '0%';
        }
    }

    else {
        //pc向け

        //workの画像切り替え
        // worBtn1にホバーしたときの処理
        document.getElementById('worBtn1').addEventListener('mouseenter', function () {
            // すべてのworkImgのis-activeクラスを削除
            removeActiveClassFromImages();
            // workImg1にis-activeクラスを追加
            document.getElementById('workImg1').classList.add('is-active');
        });

        // worBtn2にホバーしたときの処理
        document.getElementById('worBtn2').addEventListener('mouseenter', function () {
            // すべてのworkImgのis-activeクラスを削除
            removeActiveClassFromImages();
            // workImg2にis-activeクラスを追加
            document.getElementById('workImg2').classList.add('is-active');
        });

        //svg画像の長さ
        //pc
        var line1Path = document.getElementById('line1');
        var line1 = line1Path.getTotalLength();

        var line21Path = document.getElementById('line21');
        var line21 = line21Path.getTotalLength();

        var line2Path = document.getElementById('line2');
        var line2 = line2Path.getTotalLength();

        var line3Path = document.getElementById('line3');
        var line3 = line3Path.getTotalLength();

        var line4Path = document.getElementById('line4');
        var line4 = line4Path.getTotalLength();

        var line5Path = document.getElementById('line5');
        var line5 = line5Path.getTotalLength();

        //about
        let aboutvisiblePercentage = (((window.scrollY - 500) / aboutSection.clientHeight) * line1);
        if (aboutBounding.top < windowHeight && aboutBounding.bottom > 0) {
            if (aboutvisiblePercentage < line1) {
                line1Fill.setAttributeNS(null, "stroke-dasharray", line1);
                line1Fill.setAttributeNS(null, "stroke-dashoffset", (line1 - (aboutvisiblePercentage)));
            } else {
                line1Fill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        //specail
        //line
        if (specialbeforeBounding.top < windowHeight && specialafterBounding.bottom > 0) {
            specialbeforevisiblePercentage = (((window.scrollY - aboutSection.clientHeight - 500) / specialbeforeSection.clientHeight) * line21);

            if (specialbeforevisiblePercentage < line21 && specialbeforevisiblePercentage >= 0) {
                line21Fill.setAttributeNS(null, "stroke-dasharray", line21);
                line21Fill.setAttributeNS(null, "stroke-dashoffset", (line21 - specialbeforevisiblePercentage));
            } else if (specialbeforevisiblePercentage < 0) {
                line21Fill.setAttributeNS(null, "stroke-dasharray", line21);
                line21Fill.setAttributeNS(null, "stroke-dashoffset", line21);

            } else if (specialbeforevisiblePercentage > line21) {
                line21Fill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        //cuve
        if (specialafterBounding.top < windowHeight && specialafterBounding.bottom > 0) {
            specialaftervisiblePercentage = (((window.scrollY - (aboutSection.clientHeight + specialbeforeSection.clientHeight) - 500) / specialafterSection.clientHeight) * line2 - 140);
            console.log(specialaftervisiblePercentage);

            if (specialaftervisiblePercentage < line2 && specialaftervisiblePercentage >= 0) {
                line2Fill.setAttributeNS(null, "stroke-dasharray", line21);
                line2Fill.setAttributeNS(null, "stroke-dashoffset", (line21 - specialaftervisiblePercentage));
            } else if (specialaftervisiblePercentage < 0) {
                line2Fill.setAttributeNS(null, "stroke-dasharray", line2);
                line2Fill.setAttributeNS(null, "stroke-dashoffset", line2);
            } else if (specialaftervisiblePercentage > line2) {
                line2Fill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        }

        //work
        if (workBounding.top < windowHeight && workBounding.bottom > 0) {
            workvisiblePercentage = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight) - 500) / workSection.clientHeight) * line3);
            if (workvisiblePercentage < line3) {
                line3Fill.setAttributeNS(null, "stroke-dasharray", line3);
                line3Fill.setAttributeNS(null, "stroke-dashoffset", (line3 - (workvisiblePercentage)));
            } else {
                line3Fill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }


        //benefit
        benefitvisiblePercentage = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight + workSection.clientHeight) - 500) / benefitSection.clientHeight) * line4);
        if (benefitBounding.top < windowHeight && benefitBounding.bottom > 0) {
            if (benefitvisiblePercentage < line4) {
                line4Fill.setAttributeNS(null, "stroke-dasharray", line4);
                line4Fill.setAttributeNS(null, "stroke-dashoffset", (line4 - (benefitvisiblePercentage)));
            } else {
                line4Fill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }

        //applications
        applicationsvisiblePercentage = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight + workSection.clientHeight + benefitSection.clientHeight) - 500) / applicationsSection.clientHeight) * line5);

        if (applicationsBounding.top < windowHeight && applicationsBounding.bottom > 0) {
            if (applicationsvisiblePercentage < line5) {
                line5Fill.setAttributeNS(null, "stroke-dasharray", line5);
                line5Fill.setAttributeNS(null, "stroke-dashoffset", (line5 - (applicationsvisiblePercentage)));
            } else {
                line5Fill.setAttributeNS(null, "stroke-dashoffset", 0);
            }
        } else { }


        //entry
        entryvisiblePercentage = (((window.scrollY - (aboutSection.clientHeight + specialSection.clientHeight + workSection.clientHeight + benefitSection.clientHeight + applicationsSection.clientHeight) - 500) / entrySection.clientHeight) * 100);
        if (entryvisiblePercentage > 0) {
            document.querySelector('.test').style.height = entryvisiblePercentage + '%';
            if (entryvisiblePercentage > 100) {
                document.querySelector('.test').style.height = '100%';
            }
        } else {
            document.querySelector('.test').style.height = '0%';
        }
    }
});

$(function () {
    $(".slider").slick({
        arrows: false,
        autoplay: true,
        centerMode: true,
        dots: false,
        variableWidth: true,
        slidesToShow: 2,
        centerPadding: '20%', // 適切な値に変更するか、削除してみてください
    });
});


//aboutの回転オブジェクトの処理
var carousel = $(".carousel"),
    currdeg = 0;

// 3秒ごとにrotate関数を呼び出す
var carousel = $(".carousel"),
    currdeg = 0;
var intervalId;

// 初期のタイマをセット
startRotationTimer();

$(".next").on("click", { d: "n" }, rotate);
$(".prev").on("click", { d: "p" }, rotate);

function startRotationTimer() {
    // 現在のタイマをクリア
    clearInterval(intervalId);
    // 3秒ごとにrotate関数を呼び出す
    intervalId = setInterval(function () {
        rotate({ data: { d: "n" } });
    }, 3500);
}

function rotate(e) {
    if (e.data.d == "n") {
        currdeg = currdeg - 72;
    }
    if (e.data.d == "p") {
        currdeg = currdeg + 72;
    }
    carousel.css({
        "-webkit-transform": "rotateY(" + currdeg + "deg)",
        "-moz-transform": "rotateY(" + currdeg + "deg)",
        "-o-transform": "rotateY(" + currdeg + "deg)",
        "transform": "rotateY(" + currdeg + "deg)"
    });

    // タイマをリセットして再スタート
    startRotationTimer();
}

//バナーの非表示
document.addEventListener('DOMContentLoaded', function () {
    var closeBtn = document.getElementById('closeBtn');
    var mvInternship = document.querySelector('.mv__internship');

    if (closeBtn && mvInternship) {
        closeBtn.addEventListener('click', function () {
            mvInternship.style.display = 'none';
        });
    }
});

// workBtn1
document.getElementById('worBtn1').addEventListener('mouseenter', function () {
    setWorkImageActive('workImg1');
});

// workBtn2
document.getElementById('worBtn2').addEventListener('mouseenter', function () {
    setWorkImageActive('workImg2');
});

// workBtn3
document.getElementById('worBtn3').addEventListener('mouseenter', function () {
    setWorkImageActive('workImg3');
});

function setWorkImageActive(imageId) {
    removeActiveClassFromImages();
    document.getElementById(imageId).classList.add('is-active');
}

function removeActiveClassFromImages() {
    document.getElementById('workImg1').classList.remove('is-active');
    document.getElementById('workImg2').classList.remove('is-active');
    document.getElementById('workImg3').classList.remove('is-active');
}


const splide = new Splide(".splide", {
    arrows: false,
    type: "loop", // ループ
    pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
    pauseOnFocus: false, // 矢印をクリックしてもスクロールを停止させない
    speed: 1000, // スライダーの移動時間
    padding: "20%", // スライダーの左右の余白
}).mount();



window.addEventListener('DOMContentLoaded', function () {
    // 画面の高さ
    var windowHeight = window.innerHeight;

    if (window.innerWidth <= 768) {
        // スマートフォン向けの処理

        //スクロール後にヘッダーが出現
        const fh = document.getElementById('fixed-header');
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 100) {
                fh.classList.add('is-show');
            } else {
                fh.classList.remove('is-show');
            }
        });



        // 初めに表示される画像のID
        let currentImageId = 1;

        function fadeInNextImage() {
            const currentImage = document.getElementById(`workImg${currentImageId}`);
            currentImage.classList.remove("is-active");

            currentImageId = currentImageId % 3 + 1;

            const nextImage = document.getElementById(`workImg${currentImageId}`);
            nextImage.classList.add("is-active");

            setTimeout(fadeInNextImage, 3000); // 3秒ごとに切り替える（調整可能）
        }

        setTimeout(fadeInNextImage, 3000); // 初回は3秒後に開始

        const videos = document.querySelectorAll('.video');

        videos.forEach((video) => {
            video.addEventListener('click', () => {
                if (video.paused) {
                    video.play();
                } else {
                    video.pause();
                }
            });
        });

    } else {
        //specialのホバーしたとき動画が流れる
        const videos = document.querySelectorAll('.video');
        videos.forEach((video) => {
            video.addEventListener('mouseover', () => video.play());
            video.addEventListener('mouseout', () => video.pause());
        })
    }
})